// App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignIn from './components/sing-in/SingIn';
import SignUp from './components/sign-up/SignUp';
import MarketingPage from './components/marketing-page/MarketingPage';
import Checkout from './components/checkout/Checkout';
import Dashboard from './components/dashboard/Dashboard';
import SignInSide from './components/sign-in-side/SignInSide';
import Blog from './components/blog/Blog';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/sign-in-side" element={<SignInSide />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
      </Routes>
    </Router>
  );
}
export default App;
